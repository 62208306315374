<template>
  <el-container>
    <patient-new-form v-model="showNewPatientForm" :patient_id="newPatientId" @after-save="setPatientForSearchForm"></patient-new-form>
    <patient-form v-model="showPatientForm"></patient-form>
    <contact-form v-model="showContactForm"></contact-form>

    <el-header style="background-color:#FFF;">
      <el-row class="pt-2 pb-2">
        <el-col :xs="11" :sm="9" :span="7" :xl="4" :lg="6">
          <el-button-group>

            <el-tooltip
              content="Kontakt / Supportanfrage"
              placement="bottom"
            >
              <el-button @click="showContactForm = true" icon="el-icon-chat-line-square" plain></el-button>
            </el-tooltip>

            <el-tooltip
              content="Patient aus AIS holen"
              placement="bottom"
            >
              <el-button @click="getPatientFromAIS" icon="el-icon-caret-bottom" plain></el-button>
            </el-tooltip>

            <el-tooltip
              content="Patient Stammdaten"
              placement="bottom"
              v-if="currentPatient"
            >
              <el-button @click="showPatientForm = true" icon="el-icon-user-solid" plain></el-button>
            </el-tooltip>

            <el-tooltip
              content="Einstellungen"
              placement="bottom"
            >
              <el-button icon="el-icon-setting" plain></el-button>
            </el-tooltip>

          </el-button-group>
        </el-col>
        <el-col :xs="11" :sm="13" :span="11" :xl="8" :lg="10">
          <patient-search :patient_id="currentPatientId"></patient-search>
        </el-col>
        <el-col :xs="0" :sm="0" :span="4" :xl="10" :lg="6">
          &nbsp;
        </el-col>
        <el-col :sm="2" :span="2" class="text-right align-content-end">
          <main-menu></main-menu>
        </el-col>
      </el-row>
    </el-header>
    <div class="w-100" style="background-color:#f39200; height:8px;"></div>
    <el-main>
      <el-card class="box-card">

        <el-row>
          <el-col :span="6">
            <h5><span v-if="showActiveEntries">Zu übertragende</span><span v-else>Alle</span> Meldungen</h5>
          </el-col>
          <el-col class="text-right" :span="18">
            <el-button type="primary" size="small" @click="onBtnDownload"><i class="el-icon el-icon-download"></i> Zu übertragende Meldungen herunterladen</el-button>
            <el-button type="primary" size="small" @click="onBtnShowToggle" v-bind:class="{ 'is-active': showActiveEntries }"><i class="el-icon el-icon-view"></i> <span v-if="showActiveEntries">Alle</span><span v-else>Zu übertragende</span> Meldungen anzeigen</el-button>
          </el-col>
        </el-row>

        <hr>

        <el-table
          :data="tableData"
          :default-sort = "{prop: 'date', order: 'descending'}"
          style="width: 100%"
          @row-click="onRowClick"
          stripe>
          <el-table-column
            prop="id"
            label="ID"
            width="100"
            sortable>
          </el-table-column>
          <el-table-column
            label="Patient-ID"
            width="100">
            <template #default="scope">
              #{{ scope.row.patient.id }}
            </template>
          </el-table-column>
          <el-table-column
            prop="patient"
            label="Patient"
            sortable>
            <template #default="scope">
              #{{ scope.row.patient.id }} {{ scope.row.patient.name }}, {{ scope.row.patient.firstname }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="Datum"
            sortable>
            <template #default="scope">
              {{ $filters.germanDate(scope.row.date, true) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="type"
            label="Grund"
            sortable>
            <template #default="scope">
              <span v-if="scope.row.type === 'death'">Tod</span>
              <span v-if="scope.row.type === 'diagnosis'">Diagnose</span>
              <span v-if="scope.row.type === 'systemtherapy'">Syst. Therapie</span>
              <span v-if="scope.row.type === 'progress'">Verlauf</span>
              <span v-if="scope.row.type === 'tumorconference'">Tumorkonferenz</span>
              <span v-if="scope.row.type === 'radio'">Bestrahlung</span>
              <span v-if="scope.row.type === 'therapy_op'">OP</span>
            </template>
          </el-table-column>
        </el-table>

        <!--
        <hr>

        <div v-if="currentPatient">

          <h6 class="mb-3">XML Meldung generieren für {{fullInfo}}</h6>
          <el-button type="warning" @click="openXMLWindow('therapies')">Therapien</el-button>
          <el-button type="warning" @click="openXMLWindow('progress')">Verläufe</el-button>
          <el-button type="warning" @click="openXMLWindow('death')">Tod</el-button>

        </div>
        -->

      </el-card>

    </el-main>
  </el-container>
</template>

<script>
// import PatientEcog from "@/components/PatientEcog";
import PatientNewForm from "@/components/patient/PatientNewForm";
import PatientForm from "@/components/patient/PatientForm";
import ContactForm from "@/components/parts/ContactForm";

import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import MainMenu from "@/components/parts/MainMenu";
import DacyServer from "../dacyserver";
import _ from "lodash";

export default {
  name: "CancerRegistryDashboard",
  props: [],
  components: {
    MainMenu,
    // PatientEcog,
    PatientNewForm,
    PatientForm,
    ContactForm
  },
  data () {
    return {
      tableData: [],
      currentPatientId: null,
      newPatientId: null,
      showNewPatientForm: false,
      showPatientForm: false,
      showContactForm: false,
      showActiveEntries: true
    }
  },
  mounted: async function () {
    await this.getCancerregistryEntries(false)
  },
  computed: {
    ...mapGetters('patients', ['fullInfo', 'currentPatient', 'unusedFindings']),
    ...mapGetters('cancerregistry', ['cancerregistryentries']),
  },
  watch: {
    cancerregistryentries: function (val) {
      this.tableData = _.cloneDeep(val)
    }
  },
  methods: {
    ...mapActions('patients', ['reloadCurrentPatient', 'setCurrentPatient']),
    ...mapActions('cancerregistry', ['getCancerregistryEntries']),
    async getPatientFromAIS() {
      const result = (await axios({ url: 'gdt', baseURL: 'http://127.0.0.1:3333' })).data
      if(result.error === false) {
        const patient = await this.setCurrentPatient(result.patient_id)
        if(patient === null) {
          this.newPatientId = result.patient_id
          this.showNewPatientForm = true
        }
        else {
          this.currentPatientId = result.patient_id
        }
      }
      else {
        this.$notify.error({
          message: "Ein Fehler trat bei der Kommunikation mit dem AIS auf",
          position: 'bottom-left'
        });
      }

    },
    setPatientForSearchForm() {
      this.currentPatientId = this.currentPatient.id
    },
    async onBtnShowToggle() {
      this.showActiveEntries = !this.showActiveEntries
      await this.getCancerregistryEntries(!this.showActiveEntries)
    },/*
    openXMLWindow(xmlPart) {
      if reactivated, sign url
      if(this.currentPatient) {
        const url = DacyServer.getInstance().getURL() + '/export/patients/cancerregistry/' + this.currentPatient.id + '/?part=' + xmlPart
        window.open(url, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
      }

    },*/
    async onRowClick(row) {

      const signResult = await axios.get('/sign/export/cancerregistry/single/' + row.id)
      if(signResult) {
        const url = DacyServer.getInstance().getURL() + signResult.data

          if(window.gui) window.gui.send('download-file', url)
          else window.open(url, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))

      }

    },
    async onBtnDownload() {

      const signResult = await axios.get('/sign/export/cancerregistry/unsent')
      if(signResult) {
        const url = DacyServer.getInstance().getURL() + signResult.data
        if(window.gui) window.gui.send('download-file', url)
        else window.open(url, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
      }

    }


  }

}
</script>
<style scoped>
</style>
